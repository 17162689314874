import { Base64 } from 'js-base64';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLoginData } from '../../contexts/LoginDataContext';
import { useLoginVerificationCode } from '../../services/loginService';
import { PublicRoutesList } from '../../utils/menuAndRoutesUtil';
import { performLoginUsingPassword, sendVerificationCode } from '../../api/selfieWebService';
import {
  getError, getErrorCodeFromResponse, getMaskedEmail,
} from '../../utils/functionsUtil';
import { removeLocalStorageUnlockModeRegistered, saveCurrentLoginData, saveLocalStorageUnlockModeRegistered } from '../../api/userStorage';
import { authenticate, getAuthenticationChallenge, hasCredentials } from '../../api/webAuthnApiService';
import { useToaster } from '../../contexts/ToasterContext';
import { arrayBufferToBase64 } from '../../utils/formatterUtil';
import i18n from '../../common/i18n';

const useLoginPasswordController = () => {
  const { showToast } = useToaster();
  const { currentLoginData, setCurrentLoginData } = useLoginData();
  const { processLoginResult } = useLoginVerificationCode();
  const history = useHistory();
  const location = useLocation();
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState();
  const [wasPasswordChanged, setWasPasswordChanged] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState();
  const [hasWebAuthnCredentials, setHasWebAuthnCredentials] = useState(false);
  const [errorResponse, setErrorResponse] = useState();

  const changeUser = () => {
    history.push({
      pathname: PublicRoutesList.loginDNIValidationStep,
      search: location.search,
      state: {
        askCustomerId: true, // fuerza a pedir ingreso de dni, aunque este en localStorage
      },
    });
  };

  const doLogin = async (data) => {
    try {
      setLoading(true);
      setInvalidCredentials(undefined);
      const authenticateCustomerResponse = await performLoginUsingPassword(currentLoginData.customerTaxId, data.pass);
      await processLoginResult(authenticateCustomerResponse);
    } catch (error) {
      let message = i18n.LoginPassword.invalidUserOrPass;
      if (getErrorCodeFromResponse(error) === 'LOGIN_INVALID_USER_BLOCKED') {
        message = i18n.LoginPassword.userBlocked;
      }
      setInvalidCredentials(message);
    } finally {
      setLoading(false);
    }
  };

  const continueWithSms = async () => {
    try {
      setInitLoading(true);
      const loginData = { ...currentLoginData };
      const result = await sendVerificationCode(loginData.customerTaxId);
      const { phoneNumber, smsCode, customerEmail } = result.data;
      loginData.smsCode = smsCode;
      loginData.phoneNumber = `${phoneNumber.substr(0, 4)} **** ${phoneNumber.substr(9, 10)}`;
      if (customerEmail) {
        loginData.customerEmail = getMaskedEmail(customerEmail);
      }
      setCurrentLoginData(loginData);
      saveCurrentLoginData(loginData);

      // En caso de que no recuerde la contraseña, lo envio a la pantalla de verificacion de codigo sms
      // con un flag de blanquear password
      history.push({
        pathname: PublicRoutesList.loginVerificationCodeStep,
        search: location.search,
        state: {
          resetPassword: true,
        },
      });
    } catch (error) {
      setErrorResponse(getError(error));
    } finally {
      setInitLoading(false);
    }
  };

  const retry = () => {
    setErrorResponse(undefined);
  };

  const onPasswordResetContinue = () => {
    setWasPasswordChanged(false);
  };

  const webAuthnAuthentication = async () => {
    if (!hasWebAuthnCredentials) {
      showToast(i18n.LoginPassword.webAuthnToastMessage, 'info', 'top');
      return;
    }

    try {
      setLoading(true);
      const { data: { challenge, credentialID } } = await getAuthenticationChallenge(currentLoginData.customerTaxId);

      const publicKeyCredentialRequestOptions = {
        challenge: Base64.toUint8Array(challenge),
        allowCredentials: [{
          id: Base64.toUint8Array(credentialID),
          type: 'public-key',
        }],
        timeout: 60000,
      };

      const credentialsResult = await navigator.credentials.get({
        publicKey: publicKeyCredentialRequestOptions,
      });

      const clientDataJSONEncoded = arrayBufferToBase64(credentialsResult.response.clientDataJSON);
      const authenticatorDataEncoded = arrayBufferToBase64(credentialsResult.response.authenticatorData);
      const signatureEncoded = arrayBufferToBase64(credentialsResult.response.signature);

      const credentials = {
        clientDataJSONEncoded,
        authenticatorDataEncoded,
        signatureEncoded,
      };

      const authenticateCustomerResponse = await authenticate(currentLoginData.customerTaxId, credentials);
      await processLoginResult(authenticateCustomerResponse);
    } catch (error) {
      showToast('No pudimos autenticarte', 'error');
    } finally {
      setLoading(false);
    }
  };

  const init = async () => {
    window.scrollTo(0, 0);
    setInitLoading(false);
    setWasPasswordChanged(location?.state?.resetPassword);
    const { data: hasCredential } = await hasCredentials(currentLoginData.customerTaxId);
    setHasWebAuthnCredentials(hasCredential);
    if (hasCredential) {
      saveLocalStorageUnlockModeRegistered(currentLoginData.customerTaxId);
    } else {
      removeLocalStorageUnlockModeRegistered(currentLoginData.customerTaxId);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    // eslint-disable-next-line max-len
    initLoading, loading, changeUser, doLogin, continueWithSms, invalidCredentials, errorResponse, retry, wasPasswordChanged, onPasswordResetContinue, webAuthnAuthentication,
  };
};

export default useLoginPasswordController;
