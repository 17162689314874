import {
  Box, Grid, Typography, Button, makeStyles,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@react-hook/media-query';
import CustomDialog from '../components/common/CustomDialog';
import Toast from '../components/common/Toast';
import i18n from '../common/i18n';
import PrepaidCardChangePinInfo from '../components/PrepaidCardChangePinInfo';
import { useAuth } from '../contexts/AuthContext';
import useQueryParam from '../customHook/useQueryParam';
import { getPathRouteFromKey, PublicRoutesList } from '../utils/menuAndRoutesUtil';
import Loading from '../components/common/Loading';
import BiometricValidatorPreview from '../components/BiometricValidatorPreview';
import { generateBiometricOnboardingData, generateAutologinToken } from '../api/selfieWebService';
import { getEnv, REACT_APP_LENDING_SELFIE_ENDPOINT } from '../api/env';

const useStyles = makeStyles({
  containerCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '30px',
    textAlign: 'start',
  },
  cardInfoTitle: {
    fontWeight: '800',
    fontSize: '20px',
    fontFamily: 'Poppins',
    marginLeft: '3px',
  },
});

const PrePaidCardChangePinPreview = () => {
  // Context
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width:600px)');
  const queryParam = useQueryParam();
  const [initLoading, setInitLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [codeQrValue, setCodeQrValue] = useState({});
  const [openToast, setOpenToast] = useState(false);
  const [validated, setValidated] = useState(false);
  const { currentUser } = useAuth();
  const redirectPath = getPathRouteFromKey(i18n.myCardChangePinKey);
  const reactAppBaseUrl = getEnv(REACT_APP_LENDING_SELFIE_ENDPOINT);

  const goBack = () => {
    history.push(getPathRouteFromKey(i18n.myCardKey));
  };

  const validate = () => {
    setValidated(true);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const createHiddenInput = (name, value) => {
    const i = document.createElement('input');
    i.type = 'hidden';
    i.name = name;
    i.value = value;
    return i;
  };

  const generateThirdStep = (onboardingData) => {
    const f = document.createElement('form');
    const method = onboardingData.url.includes(window.location.origin) ? 'get' : 'post';
    f.setAttribute('method', method);
    f.setAttribute('action', onboardingData.url);
    f.appendChild(createHiddenInput('idapp', 'App Cliente'));
    f.appendChild(createHiddenInput('seckey', onboardingData.seckey));
    f.appendChild(createHiddenInput('country', 'ARG'));
    f.appendChild(createHiddenInput('idmaqc_service', onboardingData.idmaqcService));
    f.appendChild(createHiddenInput('profile_services', onboardingData.profileServices));
    f.appendChild(createHiddenInput('services', onboardingData.services));
    f.appendChild(createHiddenInput('externaltxid', onboardingData.externalId));
    f.appendChild(createHiddenInput('dni', onboardingData.customerId));
    f.appendChild(createHiddenInput('sexo', currentUser.gender));
    document.body.appendChild(f);
    f.submit();
  };

  const generateValidateBiometricResult = async () => {
    setInitLoading(true);
    try {
      const res = await generateBiometricOnboardingData(currentUser?.customerTaxId, redirectPath);
      generateThirdStep(res.data);
    } catch (error) {
      console.log(error);
      setOpenToast(true);
      setInitLoading(false);
    }
  };

  const init = async () => {
    if (isMobile && queryParam.get('validated') === 'true') {
      setValidated(true);
    }
    if (!isMobile && currentUser.customerTaxId !== undefined) {
      const changePinPathRoute = getPathRouteFromKey(i18n.myCardChangePinKeyPreview);
      const changePinQueryParams = '?validated=true';
      const changePinRedirectURL = `${reactAppBaseUrl + changePinPathRoute + changePinQueryParams}`;

      const autologinToken = await generateAutologinToken(currentUser.customerTaxId, changePinRedirectURL);

      const autologinQueryParams = `?data=${autologinToken.data}&gender=${currentUser.gender}`;
      const autologinURL = `${reactAppBaseUrl + PublicRoutesList.autologin + autologinQueryParams}`;

      setCodeQrValue(autologinURL);
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      {initLoading === true ? (<Loading />)
        : (validated === false ? (
          <Grid container className={classes.containerCenter}>
            <Grid item xs={10}>
              <Grid item>
                <Typography className={classes.cardInfoTitle}>
                  {i18n.prePaidCardChangePinPreviewTitle}
                </Typography>
              </Grid>
              <Box m={2} />
              <Grid item style={{ fontSize: '14px' }}>
                <Grid item style={{ marginLeft: '3px' }}>
                  <Typography>{i18n.prePaidCardChangePinPreviewText1}</Typography>
                  <Box m={1} />
                  <Typography>{i18n.prePaidCardChangePinPreviewText2}</Typography>
                </Grid>
                <Box m={2} />
                <Button variant="text" style={{ padding: '0px' }} onClick={() => setOpenDialog(true)}>
                  {i18n.prePaidCardChangePinPreviewLinkTag}
                </Button>
              </Grid>

              <Grid item style={{ marginTop: '80px' }}>
                <Button
                  style={{ fontSize: '18px' }}
                  onClick={validate}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {i18n.prePaidCardChangePinPreviewButtonValidate}
                </Button>
              </Grid>
              <Grid item style={{ marginTop: '15px' }}>
                <Button
                  style={{ fontSize: '18px' }}
                  onClick={goBack}
                  variant="outlined"
                  fullWidth
                >
                  {i18n.prePaidCardChangePinPreviewButtonGoBack}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
          : <BiometricValidatorPreview goBack={goBack} qrValue={codeQrValue} goNext={generateValidateBiometricResult} />
        )}

      <CustomDialog
        open={openDialog}
        setOpen={setOpenDialog}
        dialogData={PrepaidCardChangePinInfo}
      />

      <Toast
        messageOnError={i18n.loginBiometricOnboardingError}
        open={openToast}
        onClose={handleCloseToast}
      />

    </>
  );
};

export default PrePaidCardChangePinPreview;
