import PropTypes from 'prop-types';
import {
  Dialog, Grid, Typography, Button,
  DialogContent,
} from '@material-ui/core';
import i18n from '../common/i18n';

const AuthenticationResetCredentialsDialog = ({
  open, setOpen, onClickEvent,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Grid container spacing={2} justifyContent="center" style={{ padding: '20px' }}>
          <Grid item xs={11} style={{ textAlign: 'center' }}>
            <Typography>{i18n.userResetCredentialsToolTipMessage}</Typography>
          </Grid>
          <Grid item xs={10} style={{ marginTop: '10px' }}>
            <Button
              onClick={onClickEvent}
              variant="contained"
              color="primary"
              fullWidth
            >
              {i18n.userResetCredentialsToolTipBtnSubmit}
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Button onClick={handleClose} variant="outlined" fullWidth>
              {i18n.userResetCredentialsToolTipBtnOK}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

AuthenticationResetCredentialsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onClickEvent: PropTypes.func.isRequired,
};

export default AuthenticationResetCredentialsDialog;
