import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import useQueryParam from '../customHook/useQueryParam';
import {
  getAgreementDocument, getDetailLoanCost, validateBiometricResult, saveBiometricResultPhoto,
} from '../api/selfieWebService';
import {
  getRefinancingInfo, getRefinancingInstallmentPlan, createRefinancingLoanRequest,
  performRefinancingDownPaymentSignAndActivateLoan,
} from '../api/refinancingApiService';
import { useRefinancingContext } from '../contexts/RefinancingContext';
import { capitalizeSentence, generateLinkWithFileInResponse, getError } from '../utils/functionsUtil';
import { PublicRoutesList, RefinancingRoutesList } from '../utils/menuAndRoutesUtil';
import {
  GET_REFINANCING_INSTALLMENT_PLAN, REFINANCING_CONFIRM_INSTALLMENT_PLAN, REFINANCING_SUMMARY_ERROR,
} from '../utils/referenceConstant';
import { getEnv, REACT_APP_LENDING_SELFIE_ENDPOINT, URL_SITE_HOME } from '../api/env';
import useBiometricService from './biometricService';
import {
  getLocalStorageRefinancingInfo, removeLocalStorageRefinancingInfo, setLocalStorageRefinancingInfo,
} from '../api/userStorage';
import { clearValueOnlyNumbers } from '../utils/cardUtils';

/* ************ HOME ************ */
export const useRefinancingHomeData = () => {
  const queryParam = useQueryParam();

  const history = useHistory();
  const { setRefinancingContext } = useRefinancingContext();

  const [loading, setLoading] = useState(true);
  const [disableLink, setDisableLink] = useState(false);
  const [responseError, setResponseError] = useState(false);

  const getDataFromHash = () => {
    const hash = queryParam.get('hash');
    const params = atob(hash).split('|');
    return {
      customerTaxId: params[0],
      maxTerm: params[1],
      maxAmount: params[2],
      date: params[3],
    };
  };

  const handleContinue = () => {
    history.push({
      pathname: RefinancingRoutesList.installmentPlan,
      search: `?hash=${queryParam.get('hash')}`,
    });
  };

  const handleTimeOver = () => {
    setDisableLink(true);
  };

  const init = async () => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);

      const dataFromHash = getDataFromHash();
      const expirationDate = new Date(dataFromHash.date);
      expirationDate.setHours(21);
      expirationDate.setMinutes(0);
      expirationDate.setSeconds(0);
      const remainingTime = (expirationDate - new Date()) / 1000;

      if (remainingTime <= 0) {
        setDisableLink(true);
      } else {
        const res = await getRefinancingInfo(dataFromHash.customerTaxId);
        if (res?.data?.refinancingAmount >= 0) {
          setRefinancingContext({
            hash: queryParam.get('hash'),
            customerFullName: capitalizeSentence(res.data.customerFullName),
            gender: res.data.gender,
            refinancingAmount: res.data.refinancingAmount,
            debtAmount: res.data.debtAmount,
            totalAmount: res.data.refinancingAmount + res.data.debtAmount,
            time: remainingTime,
            dataFromHash,
          });
        } else {
          setDisableLink(true);
        }
      }
    } catch (error) {
      setResponseError(getError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading, disableLink, responseError, handleContinue, handleTimeOver,
  };
};

/* ************ PLAN DE CUOTAS ************ */
export const useRefinancingInstallmentPlan = () => {
  const history = useHistory();
  const { refinancingContext, setRefinancingContext } = useRefinancingContext();

  const [loading, setLoading] = useState(true);
  const [installmentPlan, setInstallmentPlan] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const selectInstallment = (installment) => {
    setSelectedInstallment(installment);
  };

  const isSelected = (installment) =>
    (selectedInstallment && selectedInstallment.installment === installment.installment) || false;

  const handleContinue = async () => {
    try {
      const createLoanRequest = {
        requestedAmount: refinancingContext.refinancingAmount,
        installmentAmount: selectedInstallment.amount,
        installments: selectedInstallment.installment,
        lineId: selectedInstallment.lineId,
      };
      const res = await createRefinancingLoanRequest(refinancingContext.dataFromHash.customerTaxId, createLoanRequest);

      setRefinancingContext({
        ...refinancingContext,
        selectedInstallment,
        loanId: res.data,
      });

      history.push({
        pathname: RefinancingRoutesList.selfie,
        search: `?hash=${refinancingContext.hash}`,
      });
    } catch (error) {
      setErrorMessage(REFINANCING_CONFIRM_INSTALLMENT_PLAN);
      setResponseError(getError(error));
    }
  };

  const handleBack = () => {
    history.push({
      pathname: RefinancingRoutesList.home,
      search: `?hash=${refinancingContext.hash}`,
    });
  };

  const init = async () => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);

      const installmentPlanRequest = {
        requestedAmount: refinancingContext.refinancingAmount,
        maxInstallmentAmount: refinancingContext.dataFromHash.maxAmount,
        maxInstallments: refinancingContext.dataFromHash.maxTerm,
      };
      const res = await getRefinancingInstallmentPlan(
        refinancingContext.dataFromHash.customerTaxId,
        installmentPlanRequest,
      );

      setInstallmentPlan(res.data.reverse());
    } catch (error) {
      setErrorMessage(GET_REFINANCING_INSTALLMENT_PLAN);
      setResponseError(getError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    // eslint-disable-next-line max-len
    loading, installmentPlan, responseError, handleContinue, handleBack, selectedInstallment, selectInstallment, isSelected, errorMessage,
  };
};

/* ************ SELFIE ************ */
export const useRefinancingSelfie = () => {
  const history = useHistory();
  const { refinancingContext } = useRefinancingContext();
  const { initOnboarding } = useBiometricService();

  const [loading, setLoading] = useState(true);
  const [qr, setQr] = useState(true);

  const handleContinue = () => {
    try {
      setLoading(true);

      // guardo la info en localStorage para poder recuperarla luego del callback de biometria
      setLocalStorageRefinancingInfo(refinancingContext);
      initOnboarding(
        refinancingContext.dataFromHash.customerTaxId, refinancingContext.gender, RefinancingRoutesList.biometricResult,
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBack = () => {
    history.push({
      pathname: RefinancingRoutesList.installmentPlan,
      search: `?hash=${refinancingContext.hash}`,
    });
  };

  const init = () => {
    window.scrollTo(0, 0);
    setLoading(true);

    const baseUrl = getEnv(REACT_APP_LENDING_SELFIE_ENDPOINT);
    setQr(`${baseUrl}${RefinancingRoutesList.home}?hash=${refinancingContext.hash}`);

    setLoading(false);
  };

  useEffect(() => { init(); }, []);

  return {
    loading, handleContinue, handleBack, qr,
  };
};

/* ************ RESULTADO BIOMETRIA ************ */
export const useRefinancingBiometricResult = () => {
  const history = useHistory();
  const queryParams = useQueryParam();
  const { setRefinancingContext } = useRefinancingContext();
  const [showNotValidated, setShowNotValidated] = useState(false);

  const loadRefinancingInfo = () => {
    // Vuelvo a setear la info que estaba en localStorage al contexto y limpio localStorage
    const refinancing = getLocalStorageRefinancingInfo();
    setRefinancingContext(refinancing);
    removeLocalStorageRefinancingInfo();
    return refinancing;
  };

  const saveBiometricResult = async (refinancing) => {
    const idtx = queryParams.get('idtx');
    const externalID = queryParams.get('externaltxid') || queryParams.get('externaltrxid') || queryParams.get('externalID');
    const res = await validateBiometricResult(idtx, externalID);
    const saveBiometricPhotoRequest = { loanId: refinancing.loanId, imageBase64: res.data.imageBase64 };
    saveBiometricResultPhoto(saveBiometricPhotoRequest);
  };

  const handleContinue = () => {
    const refinancing = loadRefinancingInfo();
    saveBiometricResult(refinancing);
    history.push({
      pathname: RefinancingRoutesList.paymentMethod,
      search: `?hash=${refinancing.hash}`,
    });
  };

  const handleBack = () => {
    const refinancing = loadRefinancingInfo();
    history.push({
      pathname: RefinancingRoutesList.selfie,
      search: `?hash=${refinancing.hash}`,
    });
  };

  const handleGoInit = () => {
    const refinancing = loadRefinancingInfo();
    history.push({
      pathname: RefinancingRoutesList.home,
      search: `?hash=${refinancing.hash}`,
    });
  };

  return {
    showNotValidated, setShowNotValidated, handleContinue, handleBack, handleGoInit,
  };
};

/* ************ RESUMEN ************ */
export const useRefinancingSummary = () => {
  const history = useHistory();
  const { refinancingContext, setRefinancingContext } = useRefinancingContext();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [responseInitError, setResponseInitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleContinue = async () => {
    try {
      setLoading(true);
      const data = {
        externalLoanId: refinancingContext.loanId,
        customerTaxId: refinancingContext.dataFromHash.customerTaxId,
        customerFullName: refinancingContext.customerFullName,
        amount: refinancingContext.debtAmount,
        cardNumber: clearValueOnlyNumbers(refinancingContext.paymentInfo.number),
        cardExpirationMonth: refinancingContext.paymentInfo.expiry.substring(0, 2),
        cardExpirationYear: refinancingContext.paymentInfo.expiry.substring(3, 5),
        cvv: refinancingContext.paymentInfo.cvc,
      };

      await performRefinancingDownPaymentSignAndActivateLoan(data);

      history.push({
        pathname: RefinancingRoutesList.final,
        search: `?hash=${refinancingContext.hash}`,
      });
    } catch (error) {
      setResponseError(getError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    history.push({
      pathname: RefinancingRoutesList.paymentMethod,
      search: `?hash=${refinancingContext.hash}`,
    });
  };

  const handleGoInit = () => {
    history.push({
      pathname: RefinancingRoutesList.home,
      search: `?hash=${refinancingContext.hash}`,
    });
  };

  const showAgreement = async () => {
    try {
      setBtnLoading(true);
      const res = await getAgreementDocument(refinancingContext.loanId);
      generateLinkWithFileInResponse(res);
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(false);
    }
  };

  const init = async () => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);
      const detailLoanCostResponse = await getDetailLoanCost(refinancingContext.loanId);
      setRefinancingContext({
        ...refinancingContext,
        loanDetailCost: detailLoanCostResponse.data,
      });
    } catch (error) {
      setErrorMessage(REFINANCING_SUMMARY_ERROR);
      setResponseInitError(getError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    // eslint-disable-next-line max-len
    loading, btnLoading, agreement, setAgreement, showAgreement, handleContinue, handleBack, handleGoInit, responseInitError, errorMessage, responseError,
  };
};

/* ************ FINAL ************ */
export const useRefinancingFinal = () => {
  const history = useHistory();

  const handleContinue = () => {
    history.push({
      pathname: PublicRoutesList.loginDNIValidationStep,
    });
  };

  const handleBack = () => {
    window.location = getEnv(URL_SITE_HOME);
  };

  const init = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => { init(); }, []);

  return { handleBack, handleContinue };
};
