import { useHistory, useLocation } from 'react-router';
import { getCurrentUser, saveCurrentUser } from './userStorage';
import { instance } from './api';
import { API_SELFIE, getEnv } from './env';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import i18n from '../common/i18n';

const AxiosInterceptor = () => {
  const urlBackend = getEnv(API_SELFIE);
  const history = useHistory();
  const location = useLocation();

  const getLocalRefreshToken = () => {
    const user = getCurrentUser();
    return user?.refreshToken;
  };

  const getLocalAccessToken = () => {
    const user = getCurrentUser();
    return user?.accessToken;
  };

  const updateLocalAccessToken = (token) => {
    const user = getCurrentUser();
    user.accessToken = token;
    saveCurrentUser(user);
  };

  const refreshToken = async (originalConfig) => {
    try {
      const user = getCurrentUser();
      const rs = await instance.post(`${urlBackend}/users/${user.customerTaxId}/refreshtoken`, {
        refreshToken: getLocalRefreshToken(),
      });
      const { accessToken } = rs.data;
      await updateLocalAccessToken(accessToken);
      return instance(originalConfig);
    } catch (_error) {
      if (_error.response && _error.response.status === 403
        && _error.response.config.url.includes('/refreshtoken')) {
        // Refresh Token was expired
        history.push({
          pathname: `${getPathRouteFromKey(i18n.logoutKey)}`,
          search: location.search,
        });
      }
      throw _error;
    }
  };

  instance.interceptors.request.use(
    (config) => {
      const token = getLocalAccessToken();
      if (token) {
        const configAuth = config;
        configAuth.headers.Authorization = `Bearer ${token}`;
        return configAuth;
      }
      return config;
    },
    (error) => { throw error; },
  );

  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config;

      if (err.response && err.response.status === 401 && originalConfig.retry !== true) {
        // Access Token was expired
        originalConfig.retry = true;
        return refreshToken(originalConfig);
      }

      throw err;
    },
  );

  return <></>;
};

export default AxiosInterceptor;
