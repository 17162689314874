import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, Dialog, Grid, makeStyles, IconButton, DialogTitle, DialogContent, DialogActions,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { getRevolvingLineOfferPopUpDate, saveRevolvingLineOfferPopUpDate } from '../api/userStorage';
import i18n from '../common/i18n';

const useStyles = makeStyles({
  mainContainer: {
    textAlign: 'center',
    paddingTop: '15px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '10px',
  },
  dialogWrapper: {
    '& .MuiPaper-root': {
      borderRadius: '24px',
    },
  },
});

const RevolvingLineDialogHandler = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles();
  const history = useHistory();
  const { hasRevolvingLineOffer } = useCustomerAccountStatus();
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToRevolvingLineOffer = () => {
    history.push({ pathname: getPathRouteFromKey(i18n.revolvingLineOffer) });
  };

  const resolveImg = (page) => (page === 0
    ? '/images/revolving-line-pop-up-page-1.png'
    : '/images/revolving-line-pop-up-page-2.png');

  const resolveCta = (page) => (page === 0 ? handleNext : goToRevolvingLineOffer);

  const handleClose = async () => {
    setOpen(false);
  };

  const init = () => {
    // Muestra el popUp solo si tiene oferta y solo una vez por mes
    if (hasRevolvingLineOffer()) {
      const today = new Date();
      let popUpOfferDate = getRevolvingLineOfferPopUpDate();

      // a la ultima fecha le sumo 30 dias
      if (popUpOfferDate) {
        const plusDays = 30;
        popUpOfferDate = new Date(popUpOfferDate);
        popUpOfferDate.setDate(popUpOfferDate.getDate() + plusDays);
      }

      // si no tiene fecha guardado o ya expiró
      if (!popUpOfferDate || today > popUpOfferDate) {
        setOpen(true);
        saveRevolvingLineOfferPopUpDate(today);
      }
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          setOpen={setOpen}
          fullWidth
          maxWidth="xs"
          className={classes.dialogWrapper}
        >
          <DialogTitle
            style={{
              backgroundImage: `url(${resolveImg(currentPage)})`,
              height: isMobile ? '200px' : '340px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              textAlign: 'end',
              padding: '0px',
            }}
          >
            <IconButton style={{ zIndex: 100 }}>
              <Close onClick={handleClose} />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.mainContainer}>
            <Grid item xs={12}>
              <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
                {i18n.RevolvingLine.popUpOffer.pages[currentPage].title}
              </Typography>
              <Typography style={{ fontSize: '16px', fontWeight: '400' }}>
                {i18n.RevolvingLine.popUpOffer.pages[currentPage].subtitle}
              </Typography>
            </Grid>
          </DialogContent>

          <DialogActions className={classes.mainContainer}>
            <Grid item xs={12}>
              <Button onClick={resolveCta(currentPage)} variant="contained" color="primary" fullWidth>
                {i18n.RevolvingLine.popUpOffer.pages[currentPage].cta}
              </Button>
              <Button onClick={handleClose} variant="outlined" color="primary" fullWidth style={{ marginTop: '8px' }}>
                {i18n.RevolvingLine.popUpOffer.close}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default RevolvingLineDialogHandler;
