import i18n from '../common/i18n';
import { fillMessageWith } from './functionsUtil';
import { moneyFormatter } from './formatterUtil';

export const PAYMENT_RECEIPT = 'PAYMENT_RECEIPT';
export const USER_PROFILE_USER_CONTACT_DATA = 'USER_PROFILE_USER_CONTACT_DATA';
export const USER_PROFILE_USER_LOCATION_DATA = 'USER_PROFILE_USER_LOCATION_DATA';
export const USER_PROFILE_AVATAR = 'USER_PROFILE_AVATAR';
export const CLAIMS = 'CLAIM';
export const LOAN_INSTALLMENTS = 'LOAN_INSTALLMENTS';
export const CUSTOMER_INVOICES = 'CUSTOMER_INVOICES';
export const LOGIN_DNI_RESOLVER = 'LOGIN_DNI_RESOLVER';
export const LOGIN_IDENTITY_RESOLVER = 'LOGIN_IDENTITY_RESOLVER';
export const LOGIN_VERIFICATION_CODE = 'LOGIN_VERIFICATION_CODE';
export const LOAN_PAYMENT_VOUCHER = 'LOAN_PAYMENT_VOUCHER';
export const PRE_PAID_LOAN = 'PRE_PAID_LOAN';
export const PRE_PAID_CARD = 'PRE_PAID_CARD';
export const PRE_PAID_CARD_DETAILS = 'PRE_PAID_CARD_DETAILS';
export const PRE_PAID_CARD_DISABLE = 'PRE_PAID_CARD_DISABLE';
export const PRE_PAID_CARD_REPRINT = 'PRE_PAID_CARD_REPRINT';
export const CUSTOMER_FREE_DEBT = 'CUSTOMER_FREE_DEBT';
export const UPDATE_DEBIT_CARD = 'UPDATE_DEBIT_CARD';
export const UPDATED_DEBIT_CARD = 'UPDATED_DEBIT_CARD';
export const PAY_INSTALLMENT = 'PAY_INSTALLMENT';
export const CELLPHONE_UPDATED = 'CELLPHONE_UPDATED';
export const CHANGE_PIN = 'CHANGE_PIN';
export const SURVEY = 'SURVEY';
export const DEBIT_CARD_PAYMENT_CARD_BLOCKED = 'DEBIT_CARD_PAYMENT_CARD_BLOCKED';
export const DEBIT_CARD_PAYMENT_CARD_INSUFFICIENT_FUNDS = 'DEBIT_CARD_PAYMENT_CARD_INSUFFICIENT_FUNDS';
export const DEBIT_CARD_PAYMENT_CARD_DENIED_OR_DISABLED = 'DEBIT_CARD_PAYMENT_CARD_DENIED_OR_DISABLED';
export const DEBIT_CARD_PAYMENT_CARD_WRONG_NUMBER = 'DEBIT_CARD_PAYMENT_CARD_WRONG_NUMBER';
export const DEBIT_CARD_PAYMENT_ERROR_PAYMENT = 'DEBIT_CARD_PAYMENT_ERROR_PAYMENT';
export const DEBIT_CARD_PAYMENT_EXCEED_LIMITS = 'DEBIT_CARD_PAYMENT_EXCEED_LIMITS';
export const NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS = 'NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS';
export const NEW_NEW_LOAN_OFFER_REVOLVING_LINE_HAS_ONE_LOAN_IN_PROGRESS = 'NEW_NEW_LOAN_OFFER_REVOLVING_LINE_HAS_ONE_LOAN_IN_PROGRESS';
export const REVOLVING_LINE = 'REVOLVING_LINE';
export const INVALID_INSTALLMENT_PLAN = 'INVALID_INSTALLMENT_PLAN';
export const NEW_LOAN_OFFER_HAS_ONE_LOAN_TO_START = 'NEW_LOAN_OFFER_HAS_ONE_LOAN_TO_START';
export const NEW_LOAN_OFFER_SERVICE_MAINTENANCE = 'NEW_LOAN_OFFER_SERVICE_MAINTENANCE';
export const MY_PAYMENT_INIT_ERROR = 'MY_PAYMENT_INIT_ERROR';
export const BIOMETRIC_VALIDATION_ERROR = 'BIOMETRIC_VALIDATION_ERROR';
export const DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_MESSAGE = 'DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_MESSAGE';
export const REVOLVING_LINE_SUMMARY_ERROR = 'REVOLVING_LINE_SUMMARY_ERROR';
export const GET_REFINANCING_INFO_ERROR = 'GET_REFINANCING_INFO_ERROR';
export const GET_REFINANCING_INSTALLMENT_PLAN = 'GET_REFINANCING_INSTALLMENT_PLAN';
export const REFINANCING_CONFIRM_INSTALLMENT_PLAN = 'REFINANCING_CONFIRM_INSTALLMENT_PLAN';
export const REFINANCING_SUMMARY_ERROR = 'REFINANCING_SUMMARY_ERROR';
export const REFINANCING_SUMMARY_CONFIRM = 'REFINANCING_SUMMARY_CONFIRM';
export const REFINANCING_CODE_ISSUFICIENT_FUNDS = 'REFINANCING_CODE_ISSUFICIENT_FUNDS';
export const PAYMENT_QR_GET_INFO_ERROR = 'PAYMENT_QR_GET_INFO_ERROR';
export const PAYMENT_QR_CUSTOMER_DEBT_ERROR = 'PAYMENT_QR_CUSTOMER_DEBT_ERROR';
export const PAYMENT_QR_REVOLING_LINE_PENDING_TO_ACTIVE = 'PAYMENT_QR_REVOLING_LINE_PENDING_TO_ACTIVE';
export const PAYMENT_QR_SELECT_AMOUNT_ERROR = 'PAYMENT_QR_SELECT_AMOUNT_ERROR';
export const PAYMENT_QR_GET_OPERATION_ERROR = 'PAYMENT_QR_GET_OPERATION_ERROR';
export const PAYMENT_QR_GET_INSTALLMENT_PLAN_ERROR = 'PAYMENT_QR_GET_INSTALLMENT_PLAN_ERROR';
export const PAYMENT_QR_INSUFICIENT_FUNDS_ERROR = 'PAYMENT_QR_INSUFICIENT_FUNDS_ERROR';
export const PAYMENT_QR_NOT_GLOBAL_IDENTIFIER_00_ERROR = 'PAYMENT_QR_NOT_GLOBAL_IDENTIFIER_00_ERROR';
export const PAYMENT_QR_SELLER_NOT_ALLOWED_ERROR = 'PAYMENT_QR_SELLER_NOT_ALLOWED_ERROR';
export const PAYMENT_QR_CAMERA_UNAVAILABLE_ERROR = 'PAYMENT_QR_CAMERA_UNAVAILABLE_ERROR';
export const PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS = 'PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS';
export const PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_ERROR = 'PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_ERROR';
export const AUTHENTICATE_SET_CREDENCIALS_SUCCESS = 'AUTHENTICATE_SET_CREDENCIALS_SUCCESS';
export const AUTHENTICATE_UPDATE_CREDENCIALS_SUCCESS = 'AUTHENTICATE_UPDATE_CREDENCIALS_SUCCESS';
export const AUTHENTICATE_SET_CREDENCIALS_ERROR = 'AUTHENTICATE_SET_CREDENCIALS_ERROR';
export const WEB_AUTHN_REGISTER_SUCCESS = 'WEB_AUTHN_REGISTER_SUCCESS';

export const referenceConstant = {
  PAYMENT_RECEIPT: {
    titleSuccess: i18n.paymentReceiptTitleSuccess,
    subtitleSuccess: i18n.paymentReceiptSubtitleSuccess,
    buttonLabelSuccess: i18n.paymentReceiptButtonLabelSuccess,
    titleError: i18n.paymentReceiptTitleError,
    subtitleError: i18n.paymentReceiptSubtitleError,
    buttonLabelError: i18n.paymentReceiptButtonLabelError,
  },
  USER_PROFILE_USER_CONTACT_DATA: {
    titleSuccess: i18n.userContactDataTitleSuccess,
    subtitleSuccess: i18n.userContactDataSubtitleSuccess,
    buttonLabelSuccess: i18n.backToTop,
    titleError: i18n.userContactDataTitleError,
    subtitleError: i18n.userContactDataSubtitleError,
    buttonLabelError: i18n.userContactDataButtonLabelError,
  },
  USER_PROFILE_USER_LOCATION_DATA: {
    titleSuccess: i18n.userLocationDataTitleSuccess,
    subtitleSuccess: i18n.userLocationDataSubtitleSuccess,
    buttonLabelSuccess: i18n.backToTop,
    titleError: i18n.userLocationDataTitleError,
    subtitleError: i18n.userLocationDataSubtitleError,
    buttonLabelError: i18n.userLocationDataButtonLabelError,
  },
  CLAIM: {
    titleSuccess: i18n.claimsTitleSuccess,
    subtitleSuccess: (response) => fillMessageWith(i18n.claimsSubtitleSuccess, [response.data.ticketId]),
    buttonLabelSuccess: i18n.backToTop,
    titleError: i18n.claimsTitleError,
    subtitleError: i18n.claimsSubtitleError,
    buttonLabelError: i18n.claimsButtonLabelError,
  },
  LOAN_INSTALLMENTS: {
    titleSuccess: i18n.loanInstallmentTitleSuccess,
    subtitleSuccess: i18n.loanInstallmentSubtitleSuccess,
    buttonLabelSuccess: i18n.backToTop,
    titleError: i18n.loanInstallmentTitleError,
    subtitleError: i18n.loanInstallmentSubtitleError,
    buttonLabelError: i18n.loanInstallmentButtonLabelError,
  },
  CUSTOMER_INVOICES: {
    titleSuccess: i18n.customerInvoicesTitleSuccess,
    subtitleSuccess: i18n.customerInvoicesSubtitleSuccess,
    buttonLabelSuccess: i18n.backToTop,
    titleError: i18n.customerInvoicesTitleError,
    subtitleError: i18n.customerInvoicesSubtitleError,
    buttonLabelError: i18n.customerInvoicesButtonLabelError,
  },
  LOGIN_DNI_RESOLVER: {
    titleError: i18n.loginDNIResolverTitleError,
    subtitleError: i18n.loginDNIResolverSubtitleError,
    buttonLabelError: i18n.loginDNIResolverButtonLabelError,
  },
  LOGIN_VERIFICATION_CODE: {
    titleError: i18n.loginVerificationCodeTitleError,
    subtitleError: i18n.loginVerificationCodeSubtitleError,
    buttonLabelError: i18n.loginVerificationCodeButtonLabelError,
  },
  LOGIN_IDENTITY_RESOLVER: {
    titleError: i18n.loginIdentityResolverTitleError,
    subtitleError: i18n.loginIdentityResolverSubtitleError,
    buttonLabelError: i18n.loginIdentityResolverButtonLabelError,
  },
  LOAN_PAYMENT_VOUCHER: {
    titleSuccess: i18n.loanPaymentVouchersTitleSuccess,
    subtitleSuccess: i18n.loanPaymentVouchersSubtitleSuccess,
    buttonLabelSuccess: i18n.backToTop,
    titleError: i18n.loanPaymentVouchersTitleError,
    subtitleError: i18n.loanPaymentVouchersSubtitleError,
    buttonLabelError: i18n.loanPaymentVouchersButtonLabelError,
  },
  PRE_PAID_LOAN: {
    titleSuccess: i18n.prePaidLoanTitleSuccess,
    subtitleSuccess: i18n.prePaidLoanSubtitleSuccess,
    titleError: (response) => fillMessageWith(i18n.prePaidLoanTitleError, [response.description]),
    subtitleError: i18n.prePaidLoanSubtitleError,
    buttonLabelError: i18n.prePaidLoanButtonLabelError,
  },
  PRE_PAID_CARD: {
    titleSuccess: i18n.prePaidCardActivateTitleSuccess,
    subtitleSuccess: i18n.prePaidCardActivateSubtitleSuccess,
    buttonLabelSuccess: i18n.prePaidCardActivateButtonLabelSuccess,
    titleError: i18n.prePaidCardActivateTitleError,
    buttonLabelError: i18n.prePaidCardActivateButtonLabelError,
  },
  PRE_PAID_CARD_DETAILS: {
    titleError: i18n.prePaidCardDetailsTitleError,
    buttonLabelError: i18n.prePaidCardDetailsButtonLabelError,
  },
  PRE_PAID_CARD_DISABLE: {
    titleSuccess: i18n.prePaidCardBlockTitleSuccess,
    subtitleSuccess: i18n.prePaidCardBlockSubtitleSuccess,
    buttonLabelSuccess: i18n.prePaidCardBlockButtonLabelSuccess,
    titleError: i18n.prePaidCardBlockTitleError,
    buttonLabelError: i18n.prePaidCardBlockButtonLabelError,
  },
  PRE_PAID_CARD_REPRINT: {
    titleSuccess: i18n.prePaidCardReprintTitleSuccess,
    titleError: i18n.prePaidCardReprintTitleError,
    buttonLabelError: i18n.prePaidCardReprintButtonLabelError,
    buttonLabelSuccess: i18n.prePaidCardBlockButtonBack,
  },
  CUSTOMER_FREE_DEBT: {
    titleSuccess: i18n.customerFreeDebtTitleSuccess,
    subtitleSuccess: i18n.customerFreeDebtSubtitleSuccess,
    buttonLabelSuccess: i18n.backToTop,
    titleError: i18n.customerFreeDebtTitleError,
    subtitleError: i18n.customerFreeDebtSubtitleError,
    buttonLabelError: i18n.customerFreeDebtButtonLabelError,
  },
  PAY_INSTALLMENT: {
    titleSuccess: i18n.payInstallmentTitleSuccess,
    subtitleSuccess: i18n.payInstallmentSubtitleSuccess,
    buttonLabelSuccess: i18n.backToTop,
    titleError: i18n.payInstallmentTitleError,
    subtitleError: i18n.payInstallmentSubtitleError,
    buttonLabelError: i18n.payInstallmentButtonLabelError,
  },
  UPDATE_DEBIT_CARD: {
    titleSuccess: i18n.updateDebitCardTitleSuccess,
    subtitleSuccess: i18n.updateDebitCardSubtitleSuccess,
    titleError: i18n.updateDebitCardTitleError,
    subtitleError: i18n.updateDebitCardSubtitleError,
    buttonLabelError: i18n.updateDebitCardCTAError,
  },
  UPDATED_DEBIT_CARD: {
    titleSuccess: i18n.updatedDebitCardTitle,
    subtitleSuccess: i18n.updatedDebitCardSubtitleSuccess,
  },
  CELLPHONE_UPDATED: {
    titleSuccess: i18n.loginChangeCellphoneNumberUpdated,
    subtitleSuccess: i18n.cellphoneUpdatedTitle,
    buttonLabelSuccess: i18n.customerFreeDebtButtonLabelSuccess,
    titleError: i18n.loginChangeCellphoneNumberUpdatedError,
    subtitleError: i18n.loginChangeCellphoneNumberUpdatedSubtitleError,
  },
  CHANGE_PIN: {
    titleSuccess: i18n.prePaidCardChangePinResultSuccessTitle,
    titleError: i18n.prePaidCardChangePinResultFailTitle,
    subtitleError: i18n.prePaidCardChangePinResultFailSubtitle,
    buttonLabelSuccess: i18n.prePaidCardChangePinResultOnSuccessAndErrorSecondary,
    buttonLabelError: i18n.prePaidCardChangePinResultFailButtonPrimary,
  },
  SURVEY: {
    titleSuccess: i18n.surveyResultSuccessTitle,
    titleError: i18n.surveyResultErrorTitle,
    buttonLabelSuccess: i18n.surveyResultGoBack,
    buttonLabelError: i18n.surveyResultRetry,
  },
  DEBIT_CARD_PAYMENT_CARD_BLOCKED: {
    titleError: i18n.DEBIT_CARD_PAYMENT_CARD_BLOCKED.title,
    subtitleError: i18n.DEBIT_CARD_PAYMENT_CARD_BLOCKED.subtitle,
    buttonLabelError: i18n.paymentDebitCardRetryCTA,
  },
  DEBIT_CARD_PAYMENT_CARD_INSUFFICIENT_FUNDS: {
    titleError: i18n.DEBIT_CARD_PAYMENT_CARD_INSUFFICIENT_FUNDS.title,
    subtitleError: i18n.DEBIT_CARD_PAYMENT_CARD_INSUFFICIENT_FUNDS.subtitle,
    buttonLabelError: i18n.paymentDebitCardRetryCTA,
  },
  DEBIT_CARD_PAYMENT_CARD_DENIED_OR_DISABLED: {
    titleError: i18n.DEBIT_CARD_PAYMENT_CARD_DENIED_OR_DISABLED.title,
    subtitleError: i18n.DEBIT_CARD_PAYMENT_CARD_DENIED_OR_DISABLED.subtitle,
    buttonLabelError: i18n.paymentDebitCardRetryCTA,
  },
  DEBIT_CARD_PAYMENT_CARD_WRONG_NUMBER: {
    titleError: i18n.DEBIT_CARD_PAYMENT_CARD_WRONG_NUMBER.title,
    subtitleError: i18n.DEBIT_CARD_PAYMENT_CARD_WRONG_NUMBER.subtitle,
    buttonLabelError: i18n.paymentDebitCardRetryCTA,
  },
  DEBIT_CARD_PAYMENT_ERROR_PAYMENT: {
    titleError: i18n.DEBIT_CARD_PAYMENT_ERROR_PAYMENT.title,
    subtitleError: i18n.DEBIT_CARD_PAYMENT_ERROR_PAYMENT.subtitle,
    buttonLabelError: i18n.paymentDebitCardRetryCTA,
  },
  DEBIT_CARD_PAYMENT_EXCEED_LIMITS: {
    titleError: i18n.DEBIT_CARD_PAYMENT_EXCEED_LIMITS.title,
    subtitleError: i18n.DEBIT_CARD_PAYMENT_EXCEED_LIMITS.subtitle,
    buttonLabelError: i18n.paymentDebitCardRetryCTA,
  },
  NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS: {
    titleError: i18n.NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS.title,
    subtitleError: (response) => fillMessageWith(i18n.NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS.subtitle,
      moneyFormatter(response.data.requestedAmount || response.data.response.maxAmount)),
    buttonLabelError: i18n.NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS.buttonLabel,
  },
  NEW_NEW_LOAN_OFFER_REVOLVING_LINE_HAS_ONE_LOAN_IN_PROGRESS: {
    titleError: i18n.NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS.title,
    buttonLabelError: i18n.NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS.buttonLabel,
  },
  NEW_LOAN_OFFER_HAS_ONE_LOAN_TO_START: {
    titleError: i18n.NEW_LOAN_OFFER_HAS_ONE_LOAN_TO_START.title,
    subtitleError: (response) => fillMessageWith(i18n.NEW_LOAN_OFFER_HAS_ONE_LOAN_TO_START.subtitle,
      moneyFormatter(response.data.requestedAmount || response.data.response.maxAmount)),
    buttonLabelError: i18n.NEW_LOAN_OFFER_HAS_ONE_LOAN_TO_START.buttonLabel,
  },
  NEW_LOAN_OFFER_SERVICE_MAINTENANCE: {
    titleError: i18n.NEW_LOAN_OFFER_SERVICE_MAINTENANCE.title,
    subtitleError: i18n.NEW_LOAN_OFFER_SERVICE_MAINTENANCE.subtitle,
  },
  MY_PAYMENT_INIT_ERROR: {
    titleError: i18n.MyPayments.errors.title,
    subtitleError: i18n.MyPayments.errors.description,
    buttonLabelError: i18n.MyPayments.errors.retryCTA,
  },
  REVOLVING_LINE: {
    titleSuccess: (response) => fillMessageWith(
      i18n.RevolvingLine.MessageResponse.titleSuccess,
      moneyFormatter(response.data.amount),
    ),
    subtitleSuccess: i18n.RevolvingLine.MessageResponse.subtitleSuccess,
    buttonLabelSuccess: i18n.RevolvingLine.MessageResponse.buttonLabelSuccess,
  },
  INVALID_INSTALLMENT_PLAN: {
    titleError: i18n.RevolvingLine.MessageResponse.invalidInstallmentPlanTitle,
    subtitleError: i18n.RevolvingLine.MessageResponse.invalidInstallmentPlanSubtitle,
    buttonLabelError: i18n.RevolvingLine.MessageResponse.invalidInstallmentPlanRetry,
  },
  GET_INSTALLMENT_PLAN_ERROR: {
    titleError: i18n.RevolvingLine.MessageResponse.installmentPlanErrorTitle,
    subtitleError: i18n.RevolvingLine.MessageResponse.installmentPlanErrorSubtitle,
    buttonLabelError: i18n.RevolvingLine.MessageResponse.installmentPlanErrorRetry,
  },
  BIOMETRIC_VALIDATION_ERROR: {
    titleError: i18n.RevolvingLine.MessageResponse.biometricValidationErrorTitle,
    subtitleError: i18n.RevolvingLine.MessageResponse.biometricValidationErrorSubtitle,
    buttonLabelError: i18n.RevolvingLine.MessageResponse.biometricValidationErrorRetry,
  },
  DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_MESSAGE: {
    titleError: i18n.DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_MESSAGE.title,
    subtitleError: i18n.DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_MESSAGE.subtitle,
    buttonLabelError: i18n.paymentDebitCardRetryCTA,
  },
  REVOLVING_LINE_SUMMARY_ERROR: {
    titleError: i18n.RevolvingLine.MessageResponse.summaryErrorTitle,
  },
  GET_REFINANCING_INFO_ERROR: {
    titleError: i18n.RefinancingHome.MessageResponse.title,
  },
  GET_REFINANCING_INSTALLMENT_PLAN: {
    titleError: i18n.RefinancingInstalmentPlan.MessageResponse.getInstallmentPlanError,
  },
  REFINANCING_CONFIRM_INSTALLMENT_PLAN: {
    titleError: i18n.RefinancingInstalmentPlan.MessageResponse.confirmInstallmentPlanError,
  },
  REFINANCING_SUMMARY_ERROR: {
    titleError: i18n.RefinancingSummary.MessageResponse.getLoanInfoError,
  },
  REFINANCING_SUMMARY_CONFIRM: {
    titleError: i18n.RefinancingSummary.MessageResponse.confirmError,
  },
  REFINANCING_CODE_ISSUFICIENT_FUNDS: {
    titleError: i18n.RefinancingSummary.MessageResponse.insufficientFunds,
  },
  PAYMENT_QR_GET_INFO_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.readQR.title,
    subtitleError: i18n.PaymentQR.MessageResponse.readQR.description,
    buttonLabelError: i18n.PaymentQR.MessageResponse.readQR.cta,
  },
  PAYMENT_QR_CUSTOMER_DEBT_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.customerDebt.title,
    subtitleError: i18n.PaymentQR.MessageResponse.customerDebt.description,
    buttonLabelError: i18n.PaymentQR.MessageResponse.customerDebt.cta,
  },
  PAYMENT_QR_REVOLING_LINE_PENDING_TO_ACTIVE: {
    titleError: i18n.PaymentQR.MessageResponse.revolvingLinePendintToActivate.title,
    subtitleError: i18n.PaymentQR.MessageResponse.revolvingLinePendintToActivate.description,
    buttonLabelError: i18n.PaymentQR.MessageResponse.revolvingLinePendintToActivate.cta,
  },
  PAYMENT_QR_SELECT_AMOUNT_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.maxAmountAvailable.title,
  },
  PAYMENT_QR_GET_OPERATION_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.maxAmountAvailable.failGetOperationDetail,
  },
  PAYMENT_QR_GET_INSTALLMENT_PLAN_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.installmentPlan.title,
  },
  PAYMENT_QR_INSUFICIENT_FUNDS_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.insuficientFunds.title,
    subtitleError: i18n.PaymentQR.MessageResponse.insuficientFunds.description,
  },
  PAYMENT_QR_NOT_GLOBAL_IDENTIFIER_00_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.notGlobalPaymentIdentifier.title,
  },
  PAYMENT_QR_SELLER_NOT_ALLOWED_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.sellerNotAllowed.title,
  },
  PAYMENT_QR_CAMERA_UNAVAILABLE_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.cameraNotAvailable.title,
    subtitleError: i18n.PaymentQR.MessageResponse.cameraNotAvailable.description,
  },
  PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS: {
    titleSuccess: (response) => fillMessageWith(i18n.PaymentQR.Final.title,
      response.data.selectedAmount, response.data.sellerName),
    buttonLabelSuccess: i18n.backToTop,
  },
  PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_ERROR: {
    titleError: i18n.PaymentQR.MessageResponse.confirmPayment.title,
    subtitleError: i18n.PaymentQR.MessageResponse.confirmPayment.description,
    buttonLabelError: i18n.PaymentQR.MessageResponse.confirmPayment.cta,
  },
  AUTHENTICATE_SET_CREDENCIALS_SUCCESS: {
    titleSuccess: i18n.GeneratePassword.MessageResponseSuccess.title,
    subtitleSuccess: i18n.GeneratePassword.MessageResponseSuccess.description,
    buttonLabelSuccess: i18n.GeneratePassword.MessageResponseSuccess.cta,
  },
  AUTHENTICATE_UPDATE_CREDENCIALS_SUCCESS: {
    titleSuccess: i18n.GeneratePassword.MessageResponseUpdateSuccess.title,
    subtitleSuccess: i18n.GeneratePassword.MessageResponseUpdateSuccess.description,
    buttonLabelSuccess: i18n.GeneratePassword.MessageResponseUpdateSuccess.cta,
  },
  AUTHENTICATE_SET_CREDENCIALS_ERROR: {
    titleError: i18n.GeneratePassword.MessageResponseError.title,
    subtitleError: i18n.GeneratePassword.MessageResponseError.description,
  },
  WEB_AUTHN_REGISTER_SUCCESS: {
    titleSuccess: i18n.WebAuthnRegister.MessageResponseSuccess.title,
    subtitleSuccess: i18n.WebAuthnRegister.MessageResponseSuccess.description,
    buttonLabelSuccess: i18n.WebAuthnRegister.MessageResponseSuccess.cta,
  },
};
