import clsx from 'clsx';
import { useMediaQuery } from '@react-hook/media-query';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ClickeableBanner from '../components/ClickeableBanner';
import HomeHeader from '../components/header/HomeHeader';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import imgPrepaidCardActivateBannerDesktop from '../assets/imgs/prepaid-card-activate-banner-desktop.png';
import imgPrepaidCardActivateBanner from '../assets/imgs/prepaid-card-activate-banner.png';
import CardHomeOption from '../components/CardHomeOption';
import Loading from '../components/common/Loading';
import useHomeController from '../customHook/controller/useHomeController';
import RevolvingLineDialogHandler from '../components/RevolvingLineDialogHandler';
import NotificationDialogHandler from '../components/NotificationDialogHandler';
import PrepaidBenefitDialog from '../components/PrepaidBenefitDialog';
import BannerSlider from '../components/BannerSlider';
import CardCustomerLegalStudy from '../components/CardCustomerLegalStudy';

const useStyles = makeStyles(() => ({
  offset: {
    position: 'relative',
    top: '-50px',
  },
}));

const NewHome = () => {
  const classes = useStyles();
  const { loading, homeOptions } = useHomeController();
  const {
    hasDebt, hasPrepaidCardToActivate, hasRevolvingLine, getLegalStudyStatus, getLegalStudyContact,
  } = useCustomerAccountStatus();
  const isMobile = useMediaQuery('(max-width:600px)');

  if (loading) {
    return <Loading />;
  }

  if (getLegalStudyStatus() !== null) {
    return (
      <CardCustomerLegalStudy
        managmentLegalStudyStatus={getLegalStudyStatus()}
        legalStudyContact={getLegalStudyContact()}
      />
    );
  }

  return (
    <>
      <HomeHeader />
      <Grid container justifyContent="center" className={clsx('', { [classes.offset]: hasDebt() || hasRevolvingLine() })}>
        <Grid item xs={12} md={8} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
          {hasPrepaidCardToActivate() && (
            <Box mb={4}>
              <ClickeableBanner
                onClick={() => {}}
                imageUrl={isMobile ? imgPrepaidCardActivateBanner : imgPrepaidCardActivateBannerDesktop}
              />
            </Box>
          )}
          <Grid container justifyContent="center" spacing={1} style={{ marginBottom: '32px' }}>
            {homeOptions.map((option) => (
              <Grid item xs={4} key={option.title}>
                <CardHomeOption
                  title={option.title}
                  urlImage={option.urlImage}
                  pathOnClick={option.pathOnClick}
                />
              </Grid>
            ))}
          </Grid>
          <BannerSlider />
        </Grid>
      </Grid>

      {isMobile && (
        <RevolvingLineDialogHandler />
      )}
      <NotificationDialogHandler />
      <PrepaidBenefitDialog />
    </>
  );
};

export default NewHome;
