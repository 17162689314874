/* eslint-disable indent */
import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useAuth } from '../contexts/AuthContext';
import i18n from '../common/i18n';

import { getPathRouteFromKey, PublicRoutesList } from '../utils/menuAndRoutesUtil';

import useRouteValidation from '../customHook/useRouteValidation';

const PrivateRoute = ({
  children, path, ...props
}) => {
  const { currentUser } = useAuth();
  const { isNotAllowed } = useRouteValidation();

  const getRoute = () => {
    ReactGA.send({ hitType: 'pageview', page: path });
    return children;
  };

  return (
    <Route
      {...props}
      render={({ location }) =>

      (currentUser !== undefined && currentUser !== null ? (
        // si encuentra usuario logueado, evalua el permiso para la ruta que se quiere acceder
        isNotAllowed(path) ? (
          // si no tiene permitido el acceso a la ruta, redirige a la home
          <Redirect
            to={{
              pathname: getPathRouteFromKey(i18n.homeKey),
              state: { from: location },
            }}
          />
        ) : (
          getRoute()
        )
      ) : (
        <Redirect
          to={{
            pathname: PublicRoutesList.splash,
            state: { from: location },
          }}
        />

      ))}
    />
  );
};
PrivateRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
};
PrivateRoute.defaultProps = {
  children: undefined,

};
export default PrivateRoute;
