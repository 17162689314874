import {
  Button,
  Container,
  Grid,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  getCustomerEditableData,
  updateCustomerContactData,
  updateCustomerLocationData,
} from '../api/selfieWebService';
import { deleteCredentials, hasCredentials } from '../api/webAuthnApiService';
import i18n from '../common/i18n';
import { useAuth } from '../contexts/AuthContext';
import {
  replaceNullValuesByEmptyString,
  replaceNullValuesByUndefined,
  getError,
  isMobileOperatingSystem,
} from '../utils/functionsUtil';
import { removeWebAuthnRegister, saveCurrentUser } from '../api/userStorage';

import AvatarUser from '../components/AvatarUser';
import Header from '../components/common/Header';
import MessageResponse from '../components/common/MessageResponse';
import UserContactData from '../components/UserContactData';
import UserLocationData from '../components/UserLocationData';
import useHomeNavigation from '../customHook/useHomeNavigation';
import { USER_PROFILE_USER_CONTACT_DATA, USER_PROFILE_USER_LOCATION_DATA } from '../utils/referenceConstant';
import Loading from '../components/common/Loading';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import AuthenticationResetCredentialsDialog from '../components/AuthenticationResetCredentialsDialog';
import { useToaster } from '../contexts/ToasterContext';

const UserProfile = () => {
  const history = useHistory();
  const { showToast } = useToaster();
  const [initLoading, setInitLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasCredentialsCreated, setHasCredentialsCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userContactDataVisible, setUserContactDataVisible] = useState(true);
  const [userLocationDataVisible, setUserLocationDataVisible] = useState(true);
  const { currentUser, setCurrentUser } = useAuth();
  const goHome = useHomeNavigation();
  const [initError, setInitError] = useState(false);
  const [response, setResponse] = useState(undefined);

  const [customerInfo, setCustomerInfo] = useState({
    username: '',
    dni: '',
    mobile: '',
  });

  const [contactInfo, setContactInfo] = useState({
    phone: '',
    alternativePhone: '',
    email: '',
  });

  const [locationInfo, setLocationInfo] = useState({
    state: '',
    district: null,
    city: null,
    street: '',
    number: '',
    floor: '',
    apartment: '',
    zipCode: '',
    loaded: false,
  });

  const populateCustomerInfo = () => {
    const customerData = {
      username: `${currentUser.customerFirstName} ${currentUser.customerSurname}`,
      dni: currentUser.customerDocument,
      mobile: currentUser.customerCellphone,
    };
    setCustomerInfo(customerData);
  };

  const populateContactInfo = (contactDataInput) => {
    const contactData = replaceNullValuesByEmptyString(contactDataInput);
    setContactInfo(contactData);
  };

  const populateLocationInfo = (locationDataInput) => {
    const locationData = replaceNullValuesByUndefined(locationDataInput);
    const locationDataLoaded = { ...locationData, loaded: true };
    setLocationInfo(locationDataLoaded);
  };

  const cleanCredentials = async () => {
    try {
      await deleteCredentials(currentUser.customerTaxId);
      setHasCredentialsCreated(false);
      removeWebAuthnRegister(currentUser.customerTaxId);
      history.push(getPathRouteFromKey(i18n.webAuthnRegister));
    } catch (error) {
      showToast(i18n.userResetCredentialsError, 'error');
    }
  };

  const initGetCustomerInfo = async () => {
    try {
      setInitLoading(true);
      setOpenDialog(false);
      const customerDataResponse = await getCustomerEditableData(currentUser.customerId);
      const hasCredentialsResponse = await hasCredentials(currentUser.customerTaxId);
      setHasCredentialsCreated(hasCredentialsResponse.data);
      populateCustomerInfo();
      populateContactInfo(customerDataResponse.data.contactInfo);
      populateLocationInfo(customerDataResponse.data.locationInfo);
      setInitLoading(false);
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };
  useEffect(() => initGetCustomerInfo(), []);

  const onSubmitUserContactData = async (data) => {
    try {
      setLoading(true);
      const res = await updateCustomerContactData(currentUser.customerId, data);
      setResponse(res);
      setCurrentUser({ ...currentUser, customerEmail: data.email });
      saveCurrentUser({ ...currentUser, customerEmail: data.email });
    } catch (error) {
      setLoading(false);
      setResponse(getError(error));
    }
  };

  const onSubmitUserLocationData = async (data) => {
    try {
      setLoading(true);
      const res = await updateCustomerLocationData(currentUser.customerId, data);
      setResponse(res);
    } catch (error) {
      setLoading(false);
      setResponse(getError(error));
    }
  };

  const handleLocationDataError = (error) => {
    setInitError(true);
    setResponse(getError(error));
  };

  const onEditUserContactDataForm = () => {
    setUserLocationDataVisible(false);
  };
  const onEditUserLocationDataForm = () => {
    setUserContactDataVisible(false);
  };
  const onCancelEditLocationDataForm = () => {
    setUserContactDataVisible(true);
  };
  const onCancelEditContactDataForm = () => {
    setUserLocationDataVisible(true);
  };

  const goToHome = () => {
    goHome();
  };

  const reload = () => {
    setUserContactDataVisible(true);
    setUserLocationDataVisible(true);
    setInitError(false);
    setResponse(undefined);
    initGetCustomerInfo();
  };
  return (
    <>
      <Header title={i18n.userProfileTitle} />
      <Box m={6} />
      <Container>
        {response === undefined && initLoading === true && (
          <Loading />
        )}
        {response === undefined && initLoading === false && (
          <div>
            <AvatarUser data={customerInfo} />
            {hasCredentialsCreated && isMobileOperatingSystem() && (
              <>
                <Button onClick={() => setOpenDialog(true)} variant="text" style={{ fontSize: 'smaller' }}>
                  {i18n.userResetCredentialsPrimaryButton}
                </Button>
              </>
            )}
            <Box m={6} />
            {userContactDataVisible && (
              <UserContactData
                data={contactInfo}
                onSubmit={onSubmitUserContactData}
                loading={loading}
                onEditForm={onEditUserContactDataForm}
                onCancelEditForm={onCancelEditContactDataForm}
              />
            )}
            {userContactDataVisible && userLocationDataVisible && (
              <>
                <Box m={3} />
                <Grid container>
                  <Grid item xs={12}>
                    <Box m={3} />
                    <hr />
                  </Grid>
                </Grid>
                <Box m={3} />
              </>
            )}
            {userLocationDataVisible && (
              <UserLocationData
                data={locationInfo}
                onSubmit={onSubmitUserLocationData}
                loading={loading}
                onEditForm={onEditUserLocationDataForm}
                onCancelEditForm={onCancelEditLocationDataForm}
                onInitError={handleLocationDataError}
              />
            )}
          </div>
        )}
        {response !== undefined && (
          <MessageResponse
            response={response}
            onSuccessPrimary={goToHome}
            onErrorPrimary={reload}
            isInitError={initError}
            referenceLabels={userContactDataVisible ? USER_PROFILE_USER_CONTACT_DATA : USER_PROFILE_USER_LOCATION_DATA}
          />
        )}
      </Container>

      {isMobileOperatingSystem() && (
        <AuthenticationResetCredentialsDialog
          open={openDialog}
          setOpen={setOpenDialog}
          onClickEvent={cleanCredentials}
        />
      )}
    </>
  );
};

export default UserProfile;
