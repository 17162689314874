import { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import useQueryParam from '../customHook/useQueryParam';
import Header from '../components/common/Header';
import MessageResponse from '../components/common/MessageResponse';
import exclamationImg from '../assets/imgs/exclamation-yellow.svg';
import warningImg from '../assets/imgs/warning.svg';
import CalculatorLoading from '../components/common/CalculatorLoading';
import Loading from '../components/common/Loading';
import i18n from '../common/i18n';
import { getNewLoanOffer, getLoanRequest, getLastLoanRequest } from '../api/selfieWebService';
import { getError, sleep } from '../utils/functionsUtil';
import { dateLongFormatter } from '../utils/formatterUtil';
import { getEnv, URL_NEW_LOAN } from '../api/env';
import { useAuth } from '../contexts/AuthContext';
import useHomeNavigation from '../customHook/useHomeNavigation';
import {
  NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS,
  NEW_LOAN_OFFER_HAS_ONE_LOAN_TO_START,
  NEW_LOAN_OFFER_SERVICE_MAINTENANCE,
  NEW_NEW_LOAN_OFFER_REVOLVING_LINE_HAS_ONE_LOAN_IN_PROGRESS,
} from '../utils/referenceConstant';

function NewLoan() {
  const { currentUser } = useAuth();
  const [response, setResponse] = useState(undefined);
  const [loanHash, setLoanHash] = useState(undefined);
  const [customErrorImg, setCustomErrorImg] = useState(undefined);
  const [referenceLabel, setReferenceLabel] = useState(undefined);
  const [hasOneLoanOffer, setHasOneLoanOffer] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const queryParam = useQueryParam();
  const [headerTitle, setHeaderTitle] = useState(undefined);

  const goHome = useHomeNavigation();

  const redirectToCurrentLoanOfferUrl = () => {
    window.open(getEnv(URL_NEW_LOAN) + loanHash, '_SELF');
  };

  const redirectToNewLoanOfferUrl = (hash) => {
    window.open(getEnv(URL_NEW_LOAN) + hash, '_SELF');
  };

  const resolveTitle = (subproduct) => {
    let title = i18n.newLoanHeaderTitle;
    if (subproduct === '69') {
      title = i18n.newLoanRechargeHeaderTitle;
    } else if (subproduct === '91') {
      title = i18n.newLoanServiceHeaderTitle;
    } else if (subproduct === '97') {
      title = i18n.newLoanRevolvingLineActivationHeaderTitle;
    }
    return title;
  };

  const init = async (attempt) => {
    try {
      const product = queryParam.get('product');
      const subproduct = queryParam.get('subproduct');

      setHeaderTitle(resolveTitle(subproduct));

      let loanResponse;
      let isNewOffer = false;
      // search last loan 'PEN, PRE, LIQ, REC'
      const lastLoan = await getLastLoanRequest(currentUser.customerTaxId, product, subproduct);
      if (lastLoan?.data) {
        loanResponse = lastLoan;
      } else {
        // get new offer
        setOfferLoading(true);
        // by default create WT loan request
        const loanOffer = await getNewLoanOffer(currentUser.customerId, product || 19, subproduct || 38);
        const loanRequest = await getLoanRequest(loanOffer.data.hashKey);
        loanResponse = loanRequest;
        isNewOffer = true;
      }

      if (loanResponse.data?.response?.rejected) {
        // es un rechazo, muestra msg de error
        setOfferLoading(false);
        setResponse(getError(undefined));
      } else {
        // es una oferta en progreso, muestra un mensaje y cta para continuar
        loanResponse.status = 409;
        loanResponse.data.date = dateLongFormatter(loanResponse.data.date);
        await setLoanHash(loanResponse.data.hashKey);
        setHasOneLoanOffer(true);
        if (product === '46') {
          setReferenceLabel(NEW_NEW_LOAN_OFFER_REVOLVING_LINE_HAS_ONE_LOAN_IN_PROGRESS);
        } else {
          setReferenceLabel(loanResponse.data.requestedAmount && loanResponse.data.installmentAmountSelected
            && loanResponse.data.installmentsNumberSelected ? NEW_LOAN_OFFER_HAS_ONE_LOAN_IN_PROGRESS
            : NEW_LOAN_OFFER_HAS_ONE_LOAN_TO_START);
        }
        // es una oferta nueva, se redirecciona
        if (isNewOffer) {
          redirectToNewLoanOfferUrl(loanResponse.data.hashKey);
        }
        setOfferLoading(false);
        setResponse(loanResponse);
      }
    } catch (error) {
      if (error?.data?.code?.includes('THERE_IS_ANOTHER_OFFER_FOR_THE_SAME_CUIT_IN_PROGRESS')
          && (attempt === undefined || attempt < 3)) {
        await sleep(4000);
        init(attempt === undefined ? 1 : attempt + 1);
      } else {
        if (error?.data?.code?.includes('THERE_IS_ANOTHER_OFFER_FOR_THE_SAME_CUIT_IN_PROGRESS')) {
          setCustomErrorImg(exclamationImg);
        } else if (error?.data?.code?.includes('SERVICE_UNAVAILABLE_FOR_MAINTENANCE')) {
          setCustomErrorImg(warningImg);
          setReferenceLabel(NEW_LOAN_OFFER_SERVICE_MAINTENANCE);
        }
        setOfferLoading(false);
        setResponse(getError(error));
      }
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      <Header title={headerTitle} />
      <Box m={6} />
      <Grid container justify="center">
        <Grid item xs={11} md={7}>
          {!response && !offerLoading && (
            <Loading />
          )}
          {!response && offerLoading && (
            <CalculatorLoading />
          )}
          {response && !hasOneLoanOffer && (
            <MessageResponse
              response={response}
              customImgError={customErrorImg}
              referenceLabels={referenceLabel}
              isInitError={!referenceLabel}
              canGoHome
            />
          )}
          {response && hasOneLoanOffer && (
            <MessageResponse
              response={response}
              disableImg
              referenceLabels={referenceLabel}
              onErrorPrimary={redirectToCurrentLoanOfferUrl}
              canGoHome={false}
              customCTA={{
                action: goHome,
                label: i18n.backToTop,
                notShowOnSuccess: true,
              }}

            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default NewLoan;
