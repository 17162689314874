export const getCurrentLoginData = () => JSON.parse(localStorage.getItem('loginData'));

export const saveCurrentLoginData = (loginData) => localStorage.setItem('loginData', JSON.stringify(loginData));

export const removeCurrentLoginData = () => localStorage.removeItem('loginData');

export const getCurrentUser = () => JSON.parse(localStorage.getItem('userLogged'));

export const saveCurrentUser = (user) => localStorage.setItem('userLogged', JSON.stringify(user));

export const removeCurrentUser = () => localStorage.removeItem('userLogged');

export const getCustomerAccountStatus = () => JSON.parse(localStorage.getItem('customerAccountStatus'));

export const saveCustomerAccountStatus = (customerAccountStatus) => localStorage.setItem('customerAccountStatus',
  JSON.stringify(customerAccountStatus));

export const removeCustomerAccountStatus = () => localStorage.removeItem('customerAccountStatus');

export const saveRevolvingLineOfferPopUpDate = (popUpOfferDate) => localStorage.setItem('revolvingLineOfferPopUpDate',
  popUpOfferDate);

export const getRevolvingLineOfferPopUpDate = () => localStorage.getItem('revolvingLineOfferPopUpDate');

export const saveRevolvingLineData = (revolvingLineData) => localStorage.setItem('revolvingLineData',
  JSON.stringify(revolvingLineData));

export const getRevolvingLineData = () => JSON.parse(localStorage.getItem('revolvingLineData'));

// A diferencia de saveCurrentLoginData, aqui se guarda info que no se borra al loguearse ni desloguearse
// Se guarda info que agilizar futuros logins
export const saveRememberLoginData = (data) => localStorage.setItem('rememberLoginData', JSON.stringify(data));

export const removeRememberLoginData = () => localStorage.removeItem('rememberLoginData');

export const getRememberLoginData = () => JSON.parse(localStorage.getItem('rememberLoginData'));

export const getLocalStorageRefinancingInfo = () => JSON.parse(localStorage.getItem('refinancing'));
export const setLocalStorageRefinancingInfo = (data) => localStorage.setItem('refinancing', JSON.stringify(data));
export const removeLocalStorageRefinancingInfo = () => localStorage.removeItem('refinancing');

export const markPreLoginAsRead = () => localStorage.setItem('preLoginIntro', true);
export const isPreLoginRead = () => localStorage.getItem('preLoginIntro') === 'true';

export const saveLocalStorageUnlockModeRegistered = (customerTaxId) => localStorage.setItem(`hasWebAuthn${customerTaxId}`, true);
export const removeLocalStorageUnlockModeRegistered = (customerTaxId) => localStorage.removeItem(`hasWebAuthn${customerTaxId}`);
export const saveLocalStorageUnlockModeOmitForever = (customerTaxId) => localStorage.setItem(`webAuthnOmitForever${customerTaxId}`, true);
export const showWebAuthnRegister = (customerTaxId) =>
  !(localStorage.getItem(`webAuthnOmitForever${customerTaxId}`) === 'true' || localStorage.getItem(`hasWebAuthn${customerTaxId}`) === 'true');
export const removeWebAuthnRegister = (customerTaxId) => {
  localStorage.setItem(`webAuthnOmitForever${customerTaxId}`, 'false');
  localStorage.setItem(`hasWebAuthn${customerTaxId}`, 'false');
};
export const getLocalStorageVisibleAmount = () => localStorage.getItem('visibleAmount') === 'true';
export const saveLocalStorageVisibleAmount = (visible) => localStorage.setItem('visibleAmount', visible);
