import { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useCustomerAccountStatus } from '../../contexts/CustomerAccountStatusContext';
import { removeCurrentUser, removeCustomerAccountStatus } from '../../api/userStorage';
import TakeSurveyModal from '../../components/TakeSurveyModal';
import Loading from '../../components/common/Loading';

function Logout() {
  const { setCurrentUser } = useAuth();
  const { setCustomerAccountStatus } = useCustomerAccountStatus();
  const [showSurvey, setShowSurvey] = useState(false);

  const cleanStorageAndRedirect = () => {
    removeCustomerAccountStatus();
    setCustomerAccountStatus(undefined);
    removeCurrentUser();
    setCurrentUser(undefined);
    // al ser una ruta Private, cuando setea currentUser=undefined, redirecciona a login
  };

  const init = () => {
    setShowSurvey(true);
  };

  useEffect(() => init(), []);

  return (
    <>
      <Loading />
      {showSurvey && (
        <TakeSurveyModal onClose={cleanStorageAndRedirect} />
      )}
    </>
  );
}
export default Logout;
