/* eslint-disable jsx-a11y/media-has-caption */
import {
  Grid, Typography, Box, CircularProgress,
} from '@material-ui/core';
import i18n from '../../common/i18n';
import qrFrameImg from '../../assets/imgs/qr-frame.svg';
import QrCancel from '../../assets/imgs/cancel-icon.svg';
import PaymentQRMessageResponse from './paymentQRMessageResponse';
import { usePaymentQRStyles } from '../../assets/css/paymentQRStyles';
import { usePaymentQRScanner } from '../../services/paymentQRService';

const PaymentQRScanner = () => {
  const classes = usePaymentQRStyles();
  const {
    loading, responseError, errorMessage, videoEl, qrBoxEl, handleBack, handleRetry,
  } = usePaymentQRScanner();

  return (
    <>
      {loading && (
        // proccesing qr on scan success
        <Grid item container className={classes.body} justifyContent="center">
          <Grid item xs={12} style={{ paddingTop: '60px' }}>
            <CircularProgress size={55} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {i18n.PaymentQR.Scanner.readQRInProccess}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* qr scanner */}
      {!loading && !responseError && (
        <div className={classes.qrReader}>
          <video ref={videoEl} className={classes.qrReaderVideo} />
          <Box
            onClick={handleBack}
            className={classes.qrCancelBtn}
          >
            <img src={QrCancel} alt="cancel" height="16px" />
          </Box>
          <div ref={qrBoxEl} className={classes.qrReaderBox}>
            <img src={qrFrameImg} alt="Qr Frame" className={classes.qrReaderFrame} />
          </div>
          <div className={classes.qrReaderMessage}>{i18n.PaymentQR.Scanner.readQRMessage}</div>
        </div>
      )}

      {responseError && (
        <Grid container className={classes.body} justifyContent="center">
          <Grid item xs={10} md={6}>
            <PaymentQRMessageResponse
              response={responseError}
              referenceLabels={errorMessage}
              handleRetry={handleRetry}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PaymentQRScanner;
