import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import i18n from '../common/i18n';

export const generateLinkWithFileInResponse = (res) => {
  const type = res.headers['content-type'];
  const blob = new Blob([res.data], { type, encoding: 'UTF-8' });
  const link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('target', '_blank');
  link.click();
};

export const firstLetterToUpperCase = (chain) => {
  const lowerChain = chain.toLowerCase();
  return lowerChain.charAt(0).toUpperCase() + lowerChain.slice(1);
};

const fillRegexp = /\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g;

export const fillMessageWith = (message, ...args) => {
  const values = args.reduce((acc, v, i) => {
    acc[i.toString()] = v;
    return acc;
  }, {});
  return message.replace(fillRegexp, (exp, prop) => (values[prop] !== undefined ? values[prop] : ''));
};

export const capitalizeSentence = (sentence) => {
  if (sentence) {
    let response = '';
    const sentenceLower = (sentence.toString()).toLowerCase();
    const sentenceList = sentenceLower.split(' ');
    sentenceList.forEach((element) => {
      response += `${(element.substring(0, 1).toUpperCase())}${(element.substring(1))} `;
    });
    return response.trimEnd();
  }
  return sentence;
};

export const mapCodeToDescription = (code, groups) => {
  let response = '';
  if (code && groups && groups.length > 0) {
    groups.forEach((group) => {
      if (group.codes.includes(code)) {
        response = group?.description;
      }
    });
  }
  return response;
};

export const translatePaymentMethod = (method) => (
  i18n.PaymentMethods[method] || method
);

export const replaceNullValuesByEmptyString = (input) => {
  const aux = { ...input };
  Object.keys(aux).map((key) => {
    if (aux[key] === null) {
      aux[key] = '';
    }
    return aux[key];
  });
  return aux;
};

export const replaceNullValuesByUndefined = (input) => {
  const aux = { ...input };
  Object.keys(aux).map((key) => {
    if (aux[key] === null) {
      aux[key] = undefined;
    }
    return aux[key];
  });
  return aux;
};

export const getMaskedEmail = (customerEmail) => {
  const indexToMaskFrom = Math.floor(customerEmail.lastIndexOf('@') / 2);
  const indexToMaskTo = customerEmail.lastIndexOf('@');
  const emailPartOne = customerEmail.substr(0, indexToMaskFrom);
  const emailPartTwoMasked = '*'.repeat(indexToMaskTo - indexToMaskFrom);
  const emailPartThree = customerEmail.substr(indexToMaskTo);
  return `${emailPartOne + emailPartTwoMasked + emailPartThree}`;
};

export const getError = (error) => {
  if (error === undefined) {
    return { data: { status: 500 } };
  }
  return error;
};

export const printDocument = async (elementRef, fileName) => {
  const element = elementRef.current;
  const canvas = await html2canvas(element);
  const data = canvas.toDataURL('image/png');

  const Jspdf = jspdf;
  const pdf = new Jspdf();
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
  pdf.save(`${fileName}.pdf`);
};

export const getErrorCodeFromResponse = (response) => {
  let errorCode;
  if (response && response.data && response.data.message) {
    errorCode = response.data.message;
  }
  return errorCode;
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getCurrentDayOfMonth = () =>
  new Date().getDate();

export const getCurrentMonthName = () =>
  new Date().toLocaleString('default', { month: 'long' }).toLocaleUpperCase();

export const isEnableToPrepaid = (date) => {
  const MINIMUM_DAYS_TO_PRE_PAID = 10;
  const countInMilis = new Date().getTime() - new Date(date).getTime();
  const countInDays = countInMilis / (1000 * 3600 * 24);
  return countInDays > MINIMUM_DAYS_TO_PRE_PAID;
};

export const getCardType = (cardNumber) => {
  let result = '';
  if (/^5[1-5]/.test(cardNumber)) {
    // first check for MasterCard
    result = 'mastercard';
  } else if (/^4/.test(cardNumber)) {
    // then check for Visa
    result = 'visa';
  } else if (/^(5010|5012|5020|5038|5887|5895|5896|5894|5892|6010|6304|6759|6761|6763)/.test(cardNumber)) {
    // then check for Maestro
    result = 'maestro';
  } else if (/^3[47]/.test(cardNumber)) {
    // then check for AmEx
    result = 'amex';
  } else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(cardNumber)) {
    // then check for Diners
    result = 'diners';
  } else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(cardNumber)) {
    // then check for Discover
    result = 'discover';
  }
  return result;
};

export const isMobileOperatingSystem = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.window.opera;
  if (/windows phone/i.test(userAgent)) {
    return true;
  }
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

export const isLocalEnvironment = () =>
  window.location.hostname.startsWith('localhost');

export const isUATEnvironment = () =>
  window.location.hostname.startsWith('uat');

export const sendDataLayerEvent = (event) => {
  // aseguramos que se inicialice el dataLayer
  // para evitar que esté undefined cuando se hace un reload de la pagina
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};
